import React from 'react';
import img1 from '../images/logo.png';
import {Link, useNavigate} from "react-router-dom";
import '../App.css';
import {useEffect, useState,} from "react";
import {BASE_URL} from "../url";
import {getAuth, onAuthStateChanged, signOut} from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { database } from '../config';
import { BiSolidMap, BiSolidEnvelope, BiSolidPhone, BiLogoFacebookSquare, BiLogoInstagramAlt, BiLogoGmail, BiCalendarAlt } from "react-icons/bi";

function GetAllNews() {
  const [viewAllNews, setViewAllNews] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);

  const firebaseConfig = {
        apiKey: "AIzaSyBLg8kUiUo5Xb1J_Z42w04q-lZYRMrf4do",
        authDomain: "raising-smiles-auth.firebaseapp.com",
        projectId: "raising-smiles-auth",
        storageBucket: "raising-smiles-auth.appspot.com",
        messagingSenderId: "317263643857",
        appId: "1:317263643857:web:a16b8164b5d16f1b5531f0"
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    useEffect(() => {
        const checkLoggedIn = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                setLoggedIn(authUser);
            } else {
                setLoggedIn(null);
            }
        });
        return () => checkLoggedIn();
    }, []);

    useEffect(() => {
      fetch(`${BASE_URL}/getAllNews`)
        .then(response => {
          response.json().then(viewAllNews => {
            setViewAllNews(viewAllNews);
          });
        });
    }, []);
  
    let getAllNews;
    if(viewAllNews != null) {
        getAllNews = viewAllNews;
    } 
    const deleteNews = (newsId) => {
      if (window.confirm('Are you sure you want to delete this News?')) {
        fetch(`${BASE_URL}/deleteNews/${newsId}`, {
          method: 'DELETE'
        })
        .then(response => {
          if (response.ok) {
            alert('News deleted successfully');
            window.location.reload();
          } else {
            alert('Failed to delete the News');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
    };

    const history = useNavigate()
    const logout = ()=>{
        signOut(database).then(data=>{
            console.log('data', data)
            history('/login')
        })
    }
    

    return (
        <div classNameName="App">
        <header className="site-header">
        <div className="container">
            <div className="row">

                <div className="col-lg-8 col-12 d-flex flex-wrap">
                    <p className="d-flex me-4 mb-0">
                        <BiSolidMap className='r-icon-w'/>
                        Udupi, Karnataka
                    </p>

                    <p className="d-flex me-4 mb-0">
                        <BiSolidEnvelope className='r-icon-w'/>

                        <a href="mailto:info@raisingsmiles.in">
                            info@raisingsmiles.in
                        </a>
                    </p>

                    <p className="d-flex mb-0">
                        <BiSolidPhone className='r-icon-w'/>

                        <a href="tel:+91-6364746723">
                            +91-6364746723
                        </a>
                    </p>
                </div>

                <div className="col-lg-2 col-12 ms-auto d-lg-block d-none">
                    <ul className="social-icon">
                        <p className="d-flex mb-0">
                        <b>FOLLOW US : &nbsp;&nbsp;</b>
                        <a href="https://www.facebook.com/raisingsmiles"  rel="noreferrer" target='_blank'><BiLogoFacebookSquare className='r-icon-w'/></a>
                        <a href="https://www.instagram.com/raisingsmiles2015/"  rel="noreferrer" target='_blank'><BiLogoInstagramAlt className='r-icon-w'/></a>   
                        </p>
                    </ul>
                </div>

            </div>
        </div>
    </header>

    <nav className="navbar navbar-expand-lg bg-light shadow-lg">
        <div className="container">
            <Link to="/"><a className="navbar-brand" href="">
                <img src={img1} className="logo img-fluid" alt="Raising Smiles"/>
                <span>
                    Raising Smiles
                    <small>making a difference</small>
                </span>
                </a>
            </Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#home">Home</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#about">About</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#causes">Causes</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#volunteer">Volunteer</a>
                    </li>

                    <li className="nav-item">
                    <Link to="/news"><a className="nav-link click-scroll" href="#section_5">News</a></Link>
                    </li>

                    <li className="nav-item">
                    <Link to="/videos"><a className="nav-link click-scroll" href="">Videos</a></Link>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#contact">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

        <main>

        <div>
      {loggedIn ? (
        <div>
        <div className="row d-flex justify-content-center mt-5 mb-5">
            <div className="col-sm-6">
                <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-sm-6">
                            <Link to='/postNews'><div className="row d-flex justify-content-center mb-2">
                            <a href="#" className="btn btn-primary w-75">CREATE NEW POST</a>
                            </div></Link>
                        </div>
                        <div className="col-sm-6">
                            <div className="row d-flex justify-content-center mb-2">
                            <a href="#" onClick={logout} className="btn btn-danger w-75">LOGOUT</a>
                            </div>
                        </div>
                    </div> 
                    </div>
                </div>
            </div>
        </div>
        {getAllNews && getAllNews.map((newsData, k) => (
            <div className="row d-flex justify-content-center mt-5 mb-5">
                <div className="col-sm-6">
                    <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">{newsData.postHeading}</h5>
                        <p className="card-text">{newsData.imageURL}</p>
                        <p className="card-text">{newsData.postDetails}</p>
                        <div className="row">
                        <div className="col-sm-6">
                        <Link to={`/editNews/`+newsData._id}><a href="#" className="btn btn-success w-100 mb-2">EDIT</a></Link>
                        </div>
                        <div className="col-sm-6">
                        <div onClick={() => deleteNews(newsData._id)}><a href="#" className="btn btn-danger w-100">DELETE</a></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            ))}
        </div>
            
      ) : (
        <div>
            <p class="unauthorized">YOU ARE UNAUTHORIZED TO VIEW THIS PAGE, PLEASE LOGIN TO CONTINUE.</p>
            <Link to='/login'><div className="row d-flex justify-content-center mt-5 mb-5">
            <a href="#" className="btn btn-primary w-50 ">LOGIN</a>
            </div></Link>
        </div>
      )}
    </div>
        </main>

        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-12 mb-4">
                        <img src={img1} className="logo-b img-fluid" alt=""/>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                        <h5 className="site-footer-title mb-3">QUICK LINKS</h5>

                        <ul className="footer-menu">
                            <li className="footer-menu-item"><a href="#about" className="footer-menu-link">About Us</a></li>

                            <li className="footer-menu-item"><a href="#causes" className="footer-menu-link">Our Causes</a></li>

                            <Link to='/news'><li className="footer-menu-item"><a href="#" className="footer-menu-link">News</a></li></Link>

                            <Link to='/videos'><li className="footer-menu-item"><a href="#" className="footer-menu-link">Videos</a></li></Link>

                            <li className="footer-menu-item"><a href="#volunteer" className="footer-menu-link">Become a Volunteer</a></li>

                            <li className="footer-menu-item"><a href="#donor" className="footer-menu-link">Become a Donor</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mx-auto">
                        <h5 className="site-footer-title mb-3">CONTACT US</h5>

                        <p className="d-flex mb-2">
                        <BiSolidPhone className='r-icon-w'/>

                            <a href="tel:+91-6364746723" className="site-footer-link">
                                +91-6364746723
                            </a>
                        </p>

                        <p className="text-white d-flex">
                        <BiSolidEnvelope className='r-icon-w'/>

                            <a href="mailto:info@raisingsmiles.in" className="site-footer-link">
                                info@raisingsmiles.in
                            </a>
                        </p>

                        <p className="text-white d-flex mt-3">
                        <BiSolidMap className='r-icon-w'/>
                        <a href="" className="site-footer-link">
                        Raising Smiles, Kemmannu, Udupi, Karnataka - 576105
                        </a>
                        </p>

                    </div>
                </div>
            </div>
            <hr className='color-white'/>

            <div className="site-footer-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-7 col-12">
                            <p className="copyright-text mb-0">Copyright © 2023 <a href="#">Raising Smiles</a></p>
                            <p className="copyright-text mb-0">Desiged & Developed by <a href="https://www.sudheshholla.in/" target='_blank' rel="noreferrer"><strong>Sudhesh Holla</strong></a></p><br/>
                        </div>

                        <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                        <ul className="social-icon">
                            <p className="d-flex mb-0 text-white">
                            <b>FOLLOW US : &nbsp;&nbsp;</b>
                            <a href="https://www.facebook.com/raisingsmiles"  rel="noreferrer" target='_blank'><BiLogoFacebookSquare className='r-icon-w'/></a>
                            <a href="https://www.instagram.com/raisingsmiles2015/"  rel="noreferrer" target='_blank'><BiLogoInstagramAlt className='r-icon-w'/></a>   
                            </p>
                        </ul>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
        </div>
    );
}

export default GetAllNews