import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBLg8kUiUo5Xb1J_Z42w04q-lZYRMrf4do",
  authDomain: "raising-smiles-auth.firebaseapp.com",
  projectId: "raising-smiles-auth",
  storageBucket: "raising-smiles-auth.appspot.com",
  messagingSenderId: "317263643857",
  appId: "1:317263643857:web:a16b8164b5d16f1b5531f0"
};

const app = initializeApp(firebaseConfig);
export const database = getAuth(app);