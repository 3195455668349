import React from 'react';
import img1 from '../images/logo.png';
import {Link, useNavigate} from "react-router-dom";
import { useState } from "react";
import { database } from '../config';
import { signInWithEmailAndPassword } from 'firebase/auth';

import '../App.css';

import { BiSolidMap, BiSolidEnvelope, BiSolidPhone, BiLogoFacebookSquare, BiLogoInstagramAlt} from "react-icons/bi";

function LoginPage() {
    const history  = useNavigate();
  const [username, setUserName] = useState('');
  const [password, setpassword] = useState('');

  function login(ev) {
    ev.preventDefault();
    signInWithEmailAndPassword(database, username, password).then((data)=>{
        console.log(data, "authData");
        history('/getAllNews')
    }).catch(err=>{
        alert(err.code)
    })

    console.log("user details", username, password)
  }

  return (
    <div classNames="App">
    <header className="site-header">
        <div className="container">
            <div className="row">

                <div className="col-lg-8 col-12 d-flex flex-wrap">
                    <p className="d-flex me-4 mb-0">
                        <BiSolidMap className='r-icon-w'/>
                        Udupi, Karnataka
                    </p>

                    <p className="d-flex me-4 mb-0">
                        <BiSolidEnvelope className='r-icon-w'/>

                        <a href="mailto:info@raisingsmiles.in">
                            info@raisingsmiles.in
                        </a>
                    </p>

                    <p className="d-flex mb-0">
                        <BiSolidPhone className='r-icon-w'/>

                        <a href="tel:+91-6364746723">
                            +91-6364746723
                        </a>
                    </p>
                </div>

                <div className="col-lg-2 col-12 ms-auto d-lg-block d-none">
                    <ul className="social-icon">
                        <p className="d-flex mb-0">
                        <b>FOLLOW US : &nbsp;&nbsp;</b>
                        <a href="https://www.facebook.com/raisingsmiles"  rel="noreferrer" target='_blank'><BiLogoFacebookSquare className='r-icon-w'/></a>
                        <a href="https://www.instagram.com/raisingsmiles2015/"  rel="noreferrer" target='_blank'><BiLogoInstagramAlt className='r-icon-w'/></a>   
                        </p>
                    </ul>
                </div>

            </div>
        </div>
    </header>

    <nav className="navbar navbar-expand-lg bg-light shadow-lg">
        <div className="container">
            <Link to="/"><a className="navbar-brand" href="">
                <img src={img1} className="logo img-fluid" alt="Raising Smiles"/>
                <span>
                    Raising Smiles
                    <small>making a difference</small>
                </span>
                </a>
            </Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#home">Home</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#about">About</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#causes">Causes</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#volunteer">Volunteer</a>
                    </li>

                    <li className="nav-item">
                    <Link to="/news"><a className="nav-link click-scroll" href="#section_5">News</a></Link>
                    </li>

                    <li className="nav-item">
                    <Link to="/videos"><a className="nav-link click-scroll" href="">Videos</a></Link>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#contact">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <main>

    <section className="login-section section-padding" id="section_4">
        <div className="container">
            <div className="row">

                <div className="col-lg-12 col-12 d-flex align-items-center justify-content-center">

                    <form className="custom-form volunteer-form mb-5 mb-lg-0" onSubmit={login}>
                        <h3 className="mb-4 text-center">ADMIN LOGIN</h3>

                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <input type="text" name="volunteer-email" id="volunteer-email"
                                    className="form-control" placeholder="USERNAME" value={username} onChange={ev => setUserName(ev.target.value)}
                                    required/>
                            </div>

                            <div className="col-lg-12 col-12">
                                <input type="password" name="volunteer-email" id="volunteer-email" className="form-control" placeholder="PASSWORD" value={password} onChange={ev => setpassword(ev.target.value)}
                                    required/>
                            </div>
                        </div>

                        <button type="submit" className="form-control">LOGIN</button>
                    </form>
                </div>

            </div>
        </div>
    </section>

    </main>

    <footer className="site-footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-12 mb-4">
                    <img src={img1} className="logo-b img-fluid" alt=""/>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mb-4">
                    <h5 className="site-footer-title mb-3">QUICK LINKS</h5>

                    <ul className="footer-menu">
                        <li className="footer-menu-item"><a href="#about" className="footer-menu-link">About Us</a></li>

                        <li className="footer-menu-item"><a href="#causes" className="footer-menu-link">Our Causes</a></li>

                        <Link to='/news'><li className="footer-menu-item"><a href="#" className="footer-menu-link">News</a></li></Link>

                        <Link to='/videos'><li className="footer-menu-item"><a href="#" className="footer-menu-link">Videos</a></li></Link>

                        <li className="footer-menu-item"><a href="#volunteer" className="footer-menu-link">Become a Volunteer</a></li>

                        <li className="footer-menu-item"><a href="#donor" className="footer-menu-link">Become a Donor</a></li>
                    </ul>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mx-auto">
                    <h5 className="site-footer-title mb-3">CONTACT US</h5>

                    <p className="d-flex mb-2">
                    <BiSolidPhone className='r-icon-w'/>

                        <a href="tel:+91-6364746723" className="site-footer-link">
                            +91-6364746723
                        </a>
                    </p>

                    <p className="text-white d-flex">
                    <BiSolidEnvelope className='r-icon-w'/>

                        <a href="mailto:info@raisingsmiles.in" className="site-footer-link">
                            info@raisingsmiles.in
                        </a>
                    </p>

                    <p className="text-white d-flex mt-3">
                    <BiSolidMap className='r-icon-w'/>
                    <a href="" className="site-footer-link">
                    Raising Smiles, Kemmannu, Udupi, Karnataka - 576105
                    </a>
                    </p>

                </div>
            </div>
        </div>
        <hr className='color-white'/>

        <div className="site-footer-bottom">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-md-7 col-12">
                        <p className="copyright-text mb-0">Copyright © 2023 <a href="#">Raising Smiles</a></p>
                        <p className="copyright-text mb-0">Desiged & Developed by <a href="https://www.sudheshholla.in/" target='_blank' rel="noreferrer"><strong>Sudhesh Holla</strong></a></p><br/>
                    </div>

                    <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                    <ul className="social-icon">
                        <p className="d-flex mb-0 text-white">
                        <b>FOLLOW US : &nbsp;&nbsp;</b>
                        <a href="https://www.facebook.com/raisingsmiles"  rel="noreferrer" target='_blank'><BiLogoFacebookSquare className='r-icon-w'/></a>
                        <a href="https://www.instagram.com/raisingsmiles2015/"  rel="noreferrer" target='_blank'><BiLogoInstagramAlt className='r-icon-w'/></a>   
                        </p>
                    </ul>
                    </div>

                </div>
            </div>
        </div>
    </footer>
    </div>
  );
}

export default LoginPage