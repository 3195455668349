import HomePage from './HomePage';

function IndexPage() {
    return (
      <>
      <HomePage />
      </>
    )
  
}

export default IndexPage