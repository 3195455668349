import {useEffect, useState} from "react";
import {BASE_URL} from "../url";
import img1 from '../images/logo.png';

import img5 from '../images/1.png';

import imgvol from '../images/vol-bg.png';

import img6 from '../images/causes/1.png';
import img7 from '../images/causes/2.png';
import img8 from '../images/causes/3.png';
import img9 from '../images/causes/4.png';

import img17 from '../images/icons/1.png';
import img18 from '../images/icons/3.png';
import img19 from '../images/icons/2.png';
import img20 from '../images/icons/4.png';

import img21 from '../images/news/1.png';

import {Link} from "react-router-dom";

import '../App.css';
import LoadingSpinner from './LoadingSpinner';

import { BiSolidMap, BiSolidEnvelope, BiSolidPhone, BiLogoFacebookSquare, BiLogoInstagramAlt, BiLogoGmail, BiCalendarAlt, BiLogoWhatsapp } from "react-icons/bi";

function HomePage() {

    const [viewAllNews, setViewAllNews] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      fetch(`${BASE_URL}/viewAllNews`)
        .then(response => {
          response.json().then(viewAllNews => {
            setViewAllNews(viewAllNews);
            setIsLoading(false);
          });
        });
    }, []);
  
    let getAllNews;
    if(viewAllNews != null) {
        getAllNews = viewAllNews.slice(0, 3);;
    } 
    function imageFunction(imageURL){
        let pathArray = imageURL.split( '/' ); 
        let fullImageURL = `https://drive.google.com/uc?id=`+pathArray[5];
        return fullImageURL;
    } 
  return (
    <div classNameName="App">
    <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=916364746723&text=Hi, Raising Smiles!" class="whatsapp-button"><BiLogoWhatsapp/></a>
    <header className="site-header">
        <div className="container">
            <div className="row">

                <div className="col-lg-8 col-12 d-flex flex-wrap">
                    <p className="d-flex me-4 mb-0">
                        <BiSolidMap className='r-icon-w'/>
                        Udupi, Karnataka
                    </p>

                    <p className="d-flex me-4 mb-0">
                        <BiSolidEnvelope className='r-icon-w'/>

                        <a href="mailto:info@raisingsmiles.in">
                            info@raisingsmiles.in
                        </a>
                    </p>

                    <p className="d-flex mb-0">
                        <BiSolidPhone className='r-icon-w'/>

                        <a href="tel:+91-6364746723">
                            +91-6364746723
                        </a>
                    </p>
                </div>

                <div className="col-lg-2 col-12 ms-auto d-lg-block d-none">
                    <ul className="social-icon">
                        <p className="d-flex mb-0">
                        <b>FOLLOW US : &nbsp;&nbsp;</b>
                        <a href="https://www.facebook.com/raisingsmiles"  rel="noreferrer" target='_blank'><BiLogoFacebookSquare className='r-icon-w'/></a>
                        <a href="https://www.instagram.com/raisingsmiles2015/"  rel="noreferrer" target='_blank'><BiLogoInstagramAlt className='r-icon-w'/></a>   
                        </p>
                    </ul>
                </div>

            </div>
        </div>
    </header>

    <nav className="navbar navbar-expand-lg bg-light shadow-lg">
        <div className="container">
            <Link to="/"><a className="navbar-brand" href="">
                <img src={img1} className="logo img-fluid" alt="Raising Smiles"/>
                <span>
                    Raising Smiles
                    <small>making a difference</small>
                </span>
                </a>
            </Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#home">Home</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#about">About</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#causes">Causes</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#volunteer">Volunteer</a>
                    </li>

                    <li className="nav-item">
                    <Link to="/news"><a className="nav-link click-scroll" href="#section_5">News</a></Link>
                    </li>

                    <li className="nav-item">
                    <Link to="/videos"><a className="nav-link click-scroll" href="">Videos</a></Link>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link click-scroll" href="#contact">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <main>
    
        <section id="home" className="d-flex align-items-center">
            <div className="container text-center position-relative" data-aos="fade-in" data-aos-delay="200">
            <h1 className="color-white head-font">Raising Smiles</h1>
            <h2 className="color-grey">"You have come to this world, to do something beneficial for everybody. Do not stay entangled in thinking only what you will get. There is nothing for you to take away from this world. You have come to give. - Sri Sri."</h2>
            </div>
        </section>

        <section className="section-padding">
            <div className="container">
                <div className="row">

                    <div className="col-lg-10 col-12 text-center mx-auto">
                        <h2 className="mb-5">Welcome to Raising Smiles!</h2>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
                        <div className="featured-block d-flex justify-content-center align-items-center">
                            <a href="donate.html" className="d-block">
                                <img src={img17} className="featured-block-image img-fluid" alt=""/>

                                <p className="featured-block-text">Helping <strong>Hands</strong></p>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
                        <div className="featured-block d-flex justify-content-center align-items-center">
                            <a href="donate.html" className="d-block">
                                <img src={img19} className="featured-block-image img-fluid" alt=""/>

                                <p className="featured-block-text"><strong>Community </strong> Support</p>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                        <div className="featured-block d-flex justify-content-center align-items-center">
                            <a href="donate.html" className="d-block">
                                <img src={img18} className="featured-block-image img-fluid" alt=""/>

                                <p className="featured-block-text">Spreading <strong>Happiness</strong></p>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                        <div className="featured-block d-flex justify-content-center align-items-center">
                            <a href="donate.html" className="d-block">
                                <img src={img20} className="featured-block-image img-fluid" alt=""/>

                                <p className="featured-block-text"><strong>Empowering </strong>Change</p>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="section-padding section-bg" id="about">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                        <img src={img5}
                            className="img-fluid img-top" alt=""/>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="custom-text-box">
                            <h2 className="mb-2">Our Story</h2>

                            <h5 className="mb-3">Raising Smiles, making a difference</h5>

                            <p className="mb-0 para">The inception of <strong>RAISING SMILES</strong> took place on 21 December 2015 when 4(four) youths hailing from a city named Hampankatte in Udupi district of Karnataka state joined hands with a common objective - to serve the humanity. Since then, RAISING SMILES has carried out numerous humanitarian events, thus providing financial and moral support to the needy in the society. RAISING SMILES is a voluntary humanitarian association founded with a mission to provide financial relief to the poor, education aid, medical relief and advancement of any other object of general public utility and charitable activities in India irrespective of caste, creed, religion, nationality, social status and sex. Such activities are carried on directly or by contributions and donations through the common public, its well-wishers engaged in the charitable purposes and donors from India and abroad.</p>
                        </div>
                    </div>

                </div>
                <div className="row">
                            <div className="col-lg-12 col-md-6 col-12">
                                <div className="custom-text-box mb-lg-0">
                                    <h5 className="mb-3">Our Mission</h5>

                                    <p className="mb-0 para">To provide support and financial assistance to personal and social causes in the society and thereby create a difference in the society. We are committed to respond creatively to the current and emerging needs of the needy.</p>
                                    
                                </div>
                            </div>

                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="custom-text-box d-flex flex-wrap d-lg-block mb-lg-0">
                                    <div className="counter-thumb">
                                        <div className="d-flex">
                                            <span className="counter-number" data-from="1" data-to="2009"
                                                data-speed="1000">2015</span>
                                            <span className="counter-number-text"></span>
                                        </div>

                                        <span className="counter-text">Founded</span>
                                    </div>

                                    <div className="counter-thumb mt-4">
                                        <div className="d-flex">
                                            <span className="counter-number" data-from="1" data-to="120"
                                                data-speed="1000"></span>
                                            <span className="counter-number-text">15+</span>
                                        </div>

                                        <span className="counter-text">Volunteers</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
            </div>
        </section>

        <section id="donor" className="cta">
            <div className="container">

                <div className="text-center" data-aos="zoom-in">
                <h3>Do you want to make difference in the society Today ?</h3>
                <p>"Click here to chat with us and contribute to a noble cause."</p>
                <a className="cta-btn" href="https://api.whatsapp.com/send?phone=916364746723&text=Hi Raising Smiles! I want to bea  a Donor." target="_blank">Donate Now</a>
                </div>

            </div>
        </section>

        <section className="section-padding" id="causes">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12 col-12 text-center mb-4">
                        <h2>Our Causes</h2>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                        <div className="custom-block-wrap">
                            <img src={img6}
                                className="custom-block-image img-fluid" alt=""/>

                            <div className="custom-block">
                                <div className="custom-block-body">
                                    <h5 className="mb-3">Medical Aid</h5>

                                    <p className="para">"Our organization offers vital medical aid to those in need, ensuring access to essential healthcare services and improving lives."</p>
                                    <br/>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                        <div className="custom-block-wrap">
                            <img src={img7}
                                className="custom-block-image img-fluid" alt=""/>

                            <div className="custom-block">
                                <div className="custom-block-body">
                                    <h5 className="mb-3">Education Aid</h5>

                                    <p className="para">"Our organization provides crucial education aid, empowering individuals with knowledge and skills to create brighter futures and opportunities."</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="custom-block-wrap">
                            <img src={img8}
                                className="custom-block-image img-fluid" alt=""/>

                            <div className="custom-block">
                                <div className="custom-block-body">
                                    <h5 className="mb-3">Aid to Orphanages</h5>

                                    <p className="para">"We offer compassionate aid to orphanages, ensuring a nurturing environment and brighter prospects for vulnerable children in need of care."</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="custom-block-wrap">
                            <img src={img9}
                                className="custom-block-image img-fluid" alt=""/>

                            <div className="custom-block">
                                <div className="custom-block-body">
                                    <h5 className="mb-3">Social Causes</h5>

                                    <p className="para">"Our organization champions various social causes, fostering positive change, and making a meaningful impact on our communities and beyond."</p>
                                    <br/>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        

        <section className="section-padding" id="causes">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12 col-12 text-center mb-4">
                        <h2>Latest News</h2>
                    </div>
                    {isLoading ? (
                    <LoadingSpinner />
                    ) : (
                getAllNews.map((newsData, k) => (
                    <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                        <div className="custom-block-wrap">
                        <Link to='/news'><img src={imageFunction(newsData.imageURL)}
                                className="custom-block-image img-fluid" alt=""/></Link>

                            <div className="custom-block">
                                <div className="custom-block-body">
                                <Link to='/news'><p className="mb-3"><b>{newsData.postHeading}</b></p>

                                    <p className="para">
                                    {newsData.postDetails.length > 350
                                        ? `${newsData.postDetails.substring(0, 350)}...`
                                        : newsData.postDetails}
                                    </p></Link>
                                    <br/>
                                </div>

                            </div>
                        </div>
                    </div>
                    ))
                )}

                </div>
                <Link to='/news'><a className="newsBtn" href="" >VIEW MORE NEWS</a></Link>
            </div>
            
        </section>

        <section className="volunteer-section section-padding" id="volunteer">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-12">

                        <form className="custom-form volunteer-form mb-5 mb-lg-0" action="https://formsubmit.co/arnold4all@gmail.com" method="post" role="form">
                            <h3 className="mb-4">Become a volunteer today!</h3>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <input type="text" name="volunteer-name" id="volunteer-name" className="form-control"
                                        placeholder="Your Name" required/>
                                </div>

                                <div className="col-lg-6 col-12">
                                    <input type="email" name="volunteer-email" id="volunteer-email"
                                        pattern="[^ @]*@[^ @]*" className="form-control" placeholder="Your Email"
                                        required/>
                                </div>

                                <div className="col-lg-12 col-12">
                                    <input type="text" name="volunteer-subject" id="volunteer-subject"
                                        className="form-control" placeholder="Your Number" required/>
                                </div>

                            </div>

                            <textarea name="volunteer-message" rows="3" className="form-control" id="volunteer-message"
                                placeholder="Comment (Optional)"></textarea>

                            <button type="submit" className="form-control">Submit</button>
                        </form>
                    </div>

                    <div className="col-lg-6 col-12">
                        <img src={imgvol}
                            className="volunteer-image img-fluid" alt=""/>

                        <div className="custom-block-body text-center">
                            <h4 className="text-white mt-lg-3 mb-lg-3">About Volunteering</h4>

                            <p className="text-white">We invite you to join us as a volunteer because your skills and passion can make a significant impact on our causes, helping us create positive change in our community and touch more lives effectively.</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        {/* <section className="news-section section-padding" id="section_5">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12 col-12 mb-5">
                        <h2>Latest News</h2>
                    </div>
                    <div className="col-lg-12 col-12 mb-5">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                                <div className="news-block">
                                    <div className="news-block-top">
                                        <a href="news-detail.html">
                                            <img src={img21}
                                                className="news-image img-fluid" alt=""/>
                                        </a>
                                    </div>
                                </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="news-block-info">
                                <div className="d-flex mt-2">
                                    <div className="news-block-date">
                                        <p>
                                            <BiCalendarAlt className='r-icon-b'/>
                                            August 26, 2023
                                        </p>
                                    </div>
                                </div>
                                <div className="news-block-title mb-2">
                                    <h4><a href="news-detail.html" className="news-block-title-link">Event #80: Donation of Food and Daily essentials to Children of Krishnanugraha, Santhekatte.</a></h4>
                                </div>
                                <div className="news-block-body">
                                    <p>RS express sincere thanks, gratitude to Mr. Sandeep Fernandes and fly Mangalore/Dubai for contribution towards this event .🙏
    Together we make a difference ♥️</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    </div>
                    
                        

                    
                    

                </div>
            </div>
        </section> */}


        <section id="cta" className="cta-b">
            <div className="container">

                <div className="text-center" data-aos="zoom-in">
                <h3>Join our Blood Donation WhatsApp Group!</h3>
                <p>Join our Blood Donation WhatsApp group to be part of a lifesaving community, where you can help those in need with just a message. Together, we can make a meaningful difference in the lives of countless individuals through the power of giving.</p>
                <a className="cta-btn" href="https://chat.whatsapp.com/4sBhqdkCDHO0f3M9DRCSo8" target="_blank">Join Now</a>
                </div>

            </div>
        </section>
        
        <section className="contact-section section-padding" id="contact">
            <div className="container">
                <div className="row">

                    <div className="col-lg-4 col-12 ms-auto mb-5 mb-lg-0">
                        <div className="contact-info-wrap">
                            <h2>Contact Us</h2>

                            <br/><br/>

                            <div className="contact-info">

                                <p className="d-flex mb-2">
                                <BiSolidMap className='r-icon-b'/>
                                    Raising Smiles, Kemmannu, Udupi, Karnataka
                                </p><br/>

                                <p className="d-flex mb-2">
                                <BiSolidPhone className='r-icon-b'/>

                                    <a href="tel:+91-6364746723">
                                        +91-6364746723
                                    </a>
                                </p><br/>

                                <p className="d-flex">
                                <BiSolidEnvelope className='r-icon-b'/>

                                    <a href="mailto:info@raisingsmiles.in">
                                        info@raisingsmiles.in
                                    </a>
                                </p>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-12 mx-auto">
                        <form className="custom-form contact-form" action="https://formsubmit.co/arnold4all@gmail.com" method="post" role="form">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <input type="text" name="first-name" id="first-name" className="form-control"
                                        placeholder="First Name" required/>
                                </div>

                                <div className="col-lg-6 col-md-6 col-12">
                                    <input type="text" name="last-name" id="last-name" className="form-control"
                                        placeholder="Last Name" required/>
                                </div>
                            </div>

                            <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control"
                                placeholder="Your Email" required/>

                            <input type="phone" name="phone" id="phone"  className="form-control"
                                placeholder="Your Phone" required/>

                            <textarea name="message" rows="5" className="form-control" id="message"
                                placeholder="How can we assist you? Any suggestions for societal change?"></textarea>

                            <button type="submit" className="form-control">Send Message</button>
                        </form>
                    </div>

                </div>
            </div>
        </section>

    </main>

    <footer className="site-footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-12 mb-4">
                    <img src={img1} className="logo-b img-fluid" alt=""/>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mb-4">
                    <h5 className="site-footer-title mb-3">QUICK LINKS</h5>

                    <ul className="footer-menu">
                        <li className="footer-menu-item"><a href="#about" className="footer-menu-link">About Us</a></li>

                        <li className="footer-menu-item"><a href="#causes" className="footer-menu-link">Our Causes</a></li>

                        <Link to='/news'><li className="footer-menu-item"><a href="#" className="footer-menu-link">News</a></li></Link>

                        <Link to='/videos'><li className="footer-menu-item"><a href="#" className="footer-menu-link">Videos</a></li></Link>

                        <li className="footer-menu-item"><a href="#volunteer" className="footer-menu-link">Become a Volunteer</a></li>

                        <li className="footer-menu-item"><a href="#donor" className="footer-menu-link">Become a Donor</a></li>
                    </ul>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mx-auto">
                    <h5 className="site-footer-title mb-3">CONTACT US</h5>

                    <p className="d-flex mb-2">
                    <BiSolidPhone className='r-icon-w'/>

                        <a href="tel:+91-6364746723" className="site-footer-link">
                            +91-6364746723
                        </a>
                    </p>

                    <p className="text-white d-flex">
                    <BiSolidEnvelope className='r-icon-w'/>

                        <a href="mailto:info@raisingsmiles.in" className="site-footer-link">
                            info@raisingsmiles.in
                        </a>
                    </p>

                    <p className="text-white d-flex mt-3">
                    <BiSolidMap className='r-icon-w'/>
                    <a href="" className="site-footer-link">
                    Raising Smiles, Kemmannu, Udupi, Karnataka - 576105
                    </a>
                    </p>

                </div>
            </div>
        </div>
        <hr className='color-white'/>

        <div className="site-footer-bottom">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-md-7 col-12">
                        <p className="copyright-text mb-0">Copyright © 2023 <a href="#">Raising Smiles</a></p>
                        <p className="copyright-text mb-0">Desiged & Developed by <a href="https://www.sudheshholla.in/" target='_blank' rel="noreferrer"><strong>Sudhesh Holla</strong></a></p><br/>
                    </div>

                    <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                    <ul className="social-icon">
                        <p className="d-flex mb-0 text-white">
                        <b>FOLLOW US : &nbsp;&nbsp;</b>
                        <a href="https://www.facebook.com/raisingsmiles"  rel="noreferrer" target='_blank'><BiLogoFacebookSquare className='r-icon-w'/></a>
                        <a href="https://www.instagram.com/raisingsmiles2015/"  rel="noreferrer" target='_blank'><BiLogoInstagramAlt className='r-icon-w'/></a>   
                        </p>
                    </ul>
                    </div>

                </div>
            </div>
        </div>
    </footer>
    </div>
  );
}

export default HomePage