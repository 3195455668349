import './App.css';
import {Route,Routes} from "react-router-dom";
import { UserContextProvider } from './UserContext';
import IndexPage from './components/IndexPage';
import VideosPage from './components/VideosPage';
import NewsPage from './components/NewsPage';
import PostNews from './components/PostNews';
import LoginPage from './components/LoginPage';
import GetAllNews from './components/GetAllNews';
import EditNews from './components/EditNews';

function App() {
  return (
    <UserContextProvider>
      <Routes>
          <Route index element={<IndexPage />} />
          <Route path="/videos" element={<VideosPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/postNews" element={<PostNews />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/getAllNews" element={<GetAllNews />} />
          <Route path="/editNews/:id" element={<EditNews />} />
      </Routes>
    </UserContextProvider>
  );
}

export default App;